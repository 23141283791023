<template>
  <div class="bg-main">
    <el-container>
      <HeaderForm />
      <div class="video">
        <h1>ชมวีดีโอ</h1>
        <el-breadcrumb separator="/" class="m-b-20px">
          <el-breadcrumb-item to="/video">หน้าแรก</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            $route.params.date | dateTHShort
          }}</el-breadcrumb-item>
        </el-breadcrumb>

        <el-row :gutter="20">
          <el-col :span="12" v-for="(item, index) in rooms" :key="index"
            ><el-card class="box-card-video" shadow="hover">
              <h1>{{ item.name }}</h1>
              <el-button
                @click="
                  $router.push(`/video/${$route.params.date}/${item.name}`)
                "
                type="primary"
                >เข้าชม</el-button
              ></el-card
            >
          </el-col>
        </el-row>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

export default {
  name: "VideoRoomPage",
  components: {
    Footer,
    HeaderForm,
  },
  computed: {
    rooms() {
      let find = this.$store.state.videoList.find(
        (f) =>
          new Date(f.date).getTime() ===
          new Date(this.$route.params.date).getTime()
      );
      return find.rooms;
    },
  },
};
</script>
