<template>
  <div class="header">
    <div class="bar-sm hidden-md-and-up">
      <i @click="dialogMenuVisible = true" class="fa-solid fa-bars"></i>
      <el-dialog title="" :visible.sync="dialogMenuVisible" :fullscreen="true">
        <div class="menu-mobile">
          <div class="logo">
            <el-image
              :src="require('@/assets/images/logo-second.png')"
            ></el-image>
          </div>
          <div class="nav-bar">
            <div class="nav-item home">
              <a href="#home">{{ $t("menu.home") }}</a>
            </div>
            <div class="nav-item">
              <a href="/video">{{ $t("menu.video") }}</a>
            </div>
            <div class="nav-item register">
              <a href="#register">{{ $t("menu.register") }}</a>
            </div>
            <div class="nav-item accommodation">
              <a href="#accommodation">{{ $t("menu.accommodation") }}</a>
            </div>
            <div class="nav-item schedule">
              <a href="#schedule">{{ $t("menu.schedule") }}</a>
            </div>
            <div class="nav-item contact">
              <a href="#contact-us">{{ $t("menu.contactUs") }}</a>
            </div>
            <div class="nav-item">
              <a
                href="https://drive.google.com/drive/folders/1-JIdfRfI93uElpwg3G3ETzsaTcd6Tmfj"
                target="_blank"
                >{{ $t("menu.downloadDocument") }}</a
              >
            </div>
          </div>
          <div class="language">
            <el-image
              @click="setLanguage('en')"
              :src="require('@/assets/images/en.png')"
              :class="$i18n.locale === 'en' ? 'active' : ''"
            ></el-image>

            <el-image
              @click="setLanguage('th')"
              :src="require('@/assets/images/th.png')"
              :class="$i18n.locale === 'th' ? 'active' : ''"
            ></el-image>
          </div>
        </div>
      </el-dialog>
    </div>

    <div class="logo hidden-sm-and-down">
      <el-image :src="require('@/assets/images/logo.png')"></el-image>
    </div>
    <div class="nav-bar hidden-sm-and-down">
      <div class="nav-item home active">
        <a href="#home">{{ $t("menu.home") }}</a>
      </div>
      <div class="nav-item">
        <a href="/video">{{ $t("menu.video") }}</a>
      </div>
      <div class="nav-item register">
        <a href="#register">{{ $t("menu.register") }}</a>
      </div>
      <div class="nav-item accommodation">
        <a href="#accommodation">{{ $t("menu.accommodation") }}</a>
      </div>
      <div class="nav-item schedule">
        <a href="#schedule">{{ $t("menu.schedule") }}</a>
      </div>
      <div class="nav-item contact">
        <a href="#contact-us">{{ $t("menu.contactUs") }}</a>
      </div>
      <div class="nav-item">
        <a
          href="https://drive.google.com/drive/folders/1-JIdfRfI93uElpwg3G3ETzsaTcd6Tmfj"
          target="_blank"
          >{{ $t("menu.downloadDocument") }}</a
        >
      </div>
    </div>

    <div class="language hidden-sm-and-down">
      <el-image
        @click="setLanguage('en')"
        :src="require('@/assets/images/en.png')"
        :class="$i18n.locale === 'en' ? 'active' : ''"
      ></el-image>

      <el-image
        @click="setLanguage('th')"
        :src="require('@/assets/images/th.png')"
        :class="$i18n.locale === 'th' ? 'active' : ''"
      ></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  components: {},
  props: {
    msg: String,
  },
  data() {
    return {
      dialogMenuVisible: false,
    };
  },
  mounted() {
    this.setActiveNav();
  },
  methods: {
    setActiveNav() {
      const sections = document.querySelectorAll("section");
      const navLi = document.querySelectorAll(".nav-item");

      window.onscroll = () => {
        var current = "";

        sections.forEach((section) => {
          const sectionTop = section.offsetTop;

          if (pageYOffset >= sectionTop - 60) {
            current = section.getAttribute("id");
            if (!current) {
              current = "home";
            }
          }
        });

        navLi.forEach((li) => {
          li.classList.remove("active");
          if (li.classList.contains(current)) {
            li.classList.add("active");
            this.dialogMenuVisible = false;
          }
        });
      };
    },
    setLanguage(data) {
      this.$i18n.locale = data;
      this.$store.commit("setLanguage", data);
    },
  },
};
</script>
