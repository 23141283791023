export default [
  {
    date: new Date(2024, 11, 5),
    rooms: [
      {
        name: "Chaing Mai 1",
        videos: [
          {
            time: "08.45 - 10.15",
            name: "S1: RRT in ARF",
            file: "S1.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 1 (Meditop Co., Ltd) Emerging Trends in Hemoadsorption: Expanding the Scope of CytoSorb Beyond the ICU",
            file: "LS1.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 5 (Baxter Healthcare (Thailand) Co., Ltd) Advances in Peritoneal Dialysis: Towards Improved Efficacy and Safety",
            file: "LS5.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S5: Dual Organ Failure: Liver & Pancreas",
            file: "S5.mp4",
          },
        ],
      },
      {
        name: "Chaing Mai 2-3",
        videos: [
          {
            time: "08.45 – 10.15",
            name: "S2: Cardiovascular Diabetology",
            file: "S2.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 2: (Astra Zeneca (Thailand) Ltd.) Unleashing the Full Potential of SGLT2i for Cardiorenal Protection",
            file: "LS2.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "Lunch Symposium 6 (Boehringer Ingelheim (Thai) Ltd) Let’s take a SIP and Update CKD in practice",
            file: "LS6.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S6: Target therapy & Apheresis for Glomerular & Vascular Diseases",
            file: "S6.mp4",
          },
        ],
      },
      {
        name: "Chaing Mai 4-5",
        videos: [
          {
            time: "08.45 - 10.15",
            name: "S3: Osteogenic Vasculopathy",
            file: "S3.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 3 (Kyowa Kirin (Thailand) Co., Ltd.) Cinacalcet for Vascular Calcification, Marrow Fibrosis & Bone Diseases: CKD",
            file: "LS3.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "Lunch Symposium 7 (Fresenius Kabi (Thailand) Ltd.) Impact of IDPN management to improve HD patients' outcomes in clinical practice",
            file: "LS7.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S7: Sarcopenia & Frailty in CKD",
            file: "S7.mp4",
          },
        ],
      },
      {
        name: "Empress Grand Hall",
        videos: [
          {
            time: "08.45 - 10.15",
            name: "S4: Technology on New Membrane",
            file: "S4.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: 'Lunch Symposium 4 (Thai Otsuka Pharmaceutical Co., Ltd.) "First-Ever Treatment Approved for ADPKD"',
            file: "LS4.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 8 (Nikkiso Medical (Thailand) Co., Ltd) Green Dialysis for the Innovative technology with new Membrane",
            file: "LS8.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S8: Hemodiafiltration I",
            file: "S8.mp4",
          },
        ],
      },
    ],
  },
  {
    date: new Date(2024, 11, 6),
    rooms: [
      {
        name: "Chaing Mai 1",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "S9: Multi Organ Failure: Heart & Lung Replacement",
            file: "S9.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 9 (DKSH (Thailand) Limited) Exploring Ferric citrate for Optimal Hyperphosphatemia Outcomes",
            file: "LS9.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 13 (Fresenius Kabi (Thailand) Ltd.) LESS is MORE:Achieving better outcome in CKD-MBD Management Debate",
            file: "LS13.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S13: International Society For Apheresis, ISFA 2025 Apheresis for Tomorrow",
            file: "S13.mp4",
          },
        ],
      },
      {
        name: "Chaing Mai 2-3",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "S10: New wave of Renoprotection",
            file: "S10.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 10 (Bayer Thai Co., Ltd.) Non-Steroidal MRA: Maximizing Cardiorenal Protection",
            file: "LS10.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 14 (Novo Nordisk Pharma (Thailand) Ltd.) Bridging the gap: Practical applications of recent T2D and CKD finding with GLP-1Ras",
            file: "LS14.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S14: Anemic Consequences",
            file: "S14.mp4",
          },
        ],
      },
      {
        name: "Chaing Mai 4-5",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "S11: Vein Surveillance",
            file: "S11.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 11 (Apexcela Co., Ltd.) Enliven Immunosuppressants in Transplantation and Glomerulonephritis",
            file: "LS11.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 15 (Biopharm Chemicals/Kowa (Thailand) Novel Therapy for TG management with “The world’s first SPPARM α”: Beyond the benefits of TG lowering",
            file: "LS15.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S15: Cardionephrology",
            file: "S15.mp4",
          },
        ],
      },
      {
        name: "Empress Grand Hall",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "S12: Dialysis for long term Survival",
            file: "S12.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "Lunch Symposium 12 (Fresenius Medical Care Ltd.) Uremic Toxin Dynamics and Hemodiafiltration: European Insights and Therapeutic Frontiers",
            file: "LS12.mp4",
          },
          {
            time: "12.45 - 14.15",
            name: "Lunch Symposium 16 (Nipro Sales (Thailand) Co., Ltd.) New Membrane Innovation for Long Term Survival in Dialysis patients Speaker: Claudio Ronco",
            file: "LS16.mp4",
          },
          {
            time: "14.30 - 16.00",
            name: "S15: Cardionephrology",
            file: "S15.mp4",
          },
        ],
      },
    ],
  },
  {
    date: new Date(2024, 11, 7),
    rooms: [
      {
        name: "Chaing Mai 2-3",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "T2: Cardio renal & Vascular Protection",
            file: "T2.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "T6: New combination of Antidiabetes & Phosphate Binder",
            file: "T6.mp4",
          },
        ],
      },
      {
        name: "Chaing Mai 4-5",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "T3: Online Hemodiafiltration",
            file: "T3.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "T7: Dialysis Industrialzation",
            file: "T7.mp4",
          },
        ],
      },
      {
        name: "Empress Grand Hall",
        videos: [
          {
            time: "09.00 - 10.30",
            name: "T4: Application Peritoneal Dialysis (APD)",
            file: "T4.mp4",
          },
          {
            time: "11.00 - 12.30",
            name: "T8: Waste Water Purification Management & Piping System",
            file: "T8.mp4",
          },
        ],
      },
    ],
  },
];
