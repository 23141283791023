<template>
  <div class="bg-main">
    <el-container>
      <HeaderForm />
      <div class="video">
        <h1>ชมวีดีโอ</h1>
        <el-breadcrumb separator="/" class="m-b-20px">
          <el-breadcrumb-item to="/video">หน้าแรก</el-breadcrumb-item>
          <el-breadcrumb-item :to="`/video/${$route.params.date}`">{{
            $route.params.date | dateTHShort
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $route.params.room }}</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="text-left">
          <div v-for="(item, index) in videos" :key="index" class="video-item">
            <h3>{{ item.name }} ({{ item.time }} น.)</h3>

            <video width="100%" controls controlsList="nodownload">
              <source :src="`${videoURL}/${item.file}`" type="video/mp4" />

              Your browser does not support HTML video.
            </video>
          </div>
        </div>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

export default {
  name: "VideoHomePage",
  components: {
    Footer,
    HeaderForm,
  },
  computed: {
    rooms() {
      let find = this.$store.state.videoList.find(
        (f) =>
          new Date(f.date).getTime() ===
          new Date(this.$route.params.date).getTime()
      );
      return find.rooms;
    },
    videos() {
      let find = this.rooms.find((f) => f.name === this.$route.params.room);
      return find.videos;
    },
  },
  data() {
    return {
      videoURL: process.env.VUE_APP_BASE_VIDEO_FILE,
    };
  },
};
</script>
