import registerStatus from "./constant/registerStatus";
import paymentConfirmStatus from "./constant/paymentConfirmStatus";
import paymentStatus from "./constant/paymentStatus";
import reservationStatus from "./constant/reservationStatus";
import videoList from "./constant/videoList";

const initState = () => ({
  registerStatus: registerStatus,
  paymentConfirmStatus: paymentConfirmStatus,
  paymentStatus: paymentStatus,
  reservationStatus: reservationStatus,
  language: "th",
  token: "",
  videoList: videoList,
});

export default initState;
