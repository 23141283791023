<template>
  <div class="bg-main">
    <el-container>
      <HeaderForm />
      <div class="video">
        <h1>ชมวีดีโอ</h1>

        <el-row :gutter="20">
          <el-col :span="8" v-for="(item, index) in videoList" :key="index"
            ><el-card class="box-card-video" shadow="hover">
              <h1>
                {{ item.date | dateTHShort }}
              </h1>
              <el-button
                @click="$router.push(`/video/${item.date}`)"
                type="primary"
                >เข้าชม</el-button
              >
            </el-card></el-col
          >
        </el-row>
      </div>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import HeaderForm from "@/layout/HeaderForm";
import Footer from "@/layout/Footer";

export default {
  name: "VideoHomePage",
  components: {
    Footer,
    HeaderForm,
  },
  computed: {
    videoList() {
      return this.$store.state.videoList;
    },
  },
};
</script>
