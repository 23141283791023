import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import Home from "@/views/Home.vue";
import Register from "@/views/Register.vue";
import Payment from "@/views/Payment.vue";
import PaymentConfirm from "@/views/PaymentConfirm.vue";
import Reservation from "@/views/Reservation.vue";
import ReservationPayment from "@/views/ReservationPayment.vue";
import ReservationPaymentConfirm from "@/views/ReservationPaymentConfirm.vue";
import UserLogin from "@/views/UserLogin.vue";
import VideoHome from "@/views/VideoHome.vue";
import VideoRoom from "@/views/VideoRoom.vue";
import VideoPlay from "@/views/VideoPlay.vue";

import AdminLogin from "@/views/AdminLogin.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import AdminRegistered from "@/views/AdminRegistered.vue";
import AdminPaymentConfirm from "@/views/AdminPaymentConfirm.vue";
import AdminPayment from "@/views/AdminPayment.vue";
import AdminReservation from "@/views/AdminReservation.vue";
import AdminPrintReceipt from "@/views/AdminPrintReceipt.vue";
import AdminPrintCertificate from "@/views/AdminPrintCertificate.vue";
import AdminAddUser from "@/views/AdminAddUser.vue";

Vue.use(Router);

const ifAuthenticated = async (to, from, next) => {
  console.log("ifAuthenticated", store.state);
  if (!store.state.token || typeof store.state.token === "undefined") {
    next("/user-login");
  } else {
    next();
  }
};

const ifNotAuthenticated = async (to, from, next) => {
  console.log("ifAuthenticated", store.state);
  if (!store.state.token || typeof store.state.token === "undefined") {
    next();
  } else {
    next("/video");
  }
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
    },
    {
      path: "/register/:type",
      name: "Register",
      component: Register,
    },
    {
      path: "/payment/:code",
      name: "Payment",
      component: Payment,
    },
    {
      path: "/payment-confirm",
      name: "PaymentConfirm",
      component: PaymentConfirm,
    },
    {
      path: "/reservation",
      name: "Reservation",
      component: Reservation,
    },
    {
      path: "/reservation-payment/:code",
      name: "ReservationPayment",
      component: ReservationPayment,
    },
    {
      path: "/reservation-payment-confirm",
      name: "ReservationPaymentConfirm",
      component: ReservationPaymentConfirm,
    },
    {
      path: "/admin-login",
      name: "AdminLogin",
      component: AdminLogin,
    },
    {
      path: "/admin-dashboard",
      name: "AdminDashboard",
      component: AdminDashboard,
    },
    {
      path: "/admin-registered",
      name: "AdminRegistered",
      component: AdminRegistered,
    },
    {
      path: "/admin-reservation",
      name: "AdminReservation",
      component: AdminReservation,
    },
    {
      path: "/admin-payment-confirm",
      name: "AdminPaymentConfirm",
      component: AdminPaymentConfirm,
    },
    {
      path: "/admin-payment",
      name: "AdminPayment",
      component: AdminPayment,
    },
    {
      path: "/admin-print/receipt",
      name: "AdminPrintReceipt",
      component: AdminPrintReceipt,
    },
    {
      path: "/admin-print/certificate",
      name: "AdminPrintCertificate",
      component: AdminPrintCertificate,
    },
    {
      path: "/admin-add-user",
      name: "AdminAddUser",
      component: AdminAddUser,
    },
    {
      path: "/user-login",
      name: "UserLogin",
      component: UserLogin,
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: "/video",
      name: "VideoHome",
      component: VideoHome,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/video/:date",
      name: "VideoRoom",
      component: VideoRoom,
      beforeEnter: ifAuthenticated,
    },
    {
      path: "/video/:date/:room",
      name: "VideoPlay",
      component: VideoPlay,
      beforeEnter: ifAuthenticated,
    },
  ],
});
